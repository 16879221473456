<template>
  <PageContent
    class="livres-manuels-papier"
    :nav-titles="currentPage === 1 ? navTitles : null"
  >
    <template #header-right>
      <ButtonGroup :selected-id="viewMode">
        <ButtonClassic
          id="grid"
          v-matomo-log-click="['viewMode', 'grid', ]"
          variant="solid"
          icon="left"
          @click="viewMode = 'grid'"
        >
          <template #left-icon>
            <UilTable />
          </template>
        </ButtonClassic>
        <ButtonClassic
          id="list"
          v-matomo-log-click="['viewMode', 'list', ]"
          variant="solid"
          icon="left"
          @click="viewMode = 'list'"
        >
          <template #left-icon>
            <IconListView />
          </template>
        </ButtonClassic>
      </ButtonGroup>
      <ButtonClassic
        :label="$t('filtre.filtres')"
        color="primary"
        :variant="showFilterSidebar ? 'solid' : 'ghost'"
        :class="{ active: showFilterSidebar, }"
        icon="right"
        :disabled="allProductsAreLoading"
        @click="showFilterSidebar = !showFilterSidebar"
      >
        <template #right-icon>
          <UilFilter v-if="!showFilterSidebar" />
          <UilTimes v-else />
        </template>
      </ButtonClassic>
    </template>

    <template #aside-content>
      <FilterSidebar
        v-if="showFilterSidebar && allFilters.length"
        :title="$t('recherche.affiner-votre-recherche')"
        :possible-filters="allFilters"
      />
    </template>

    <template #content>
      <section
        v-if="currentPage === 1"
        id="focus"
      >
        <HomeMessages />
        <HorizontalSearchPap />
        <div
          v-if="(slides.large && slides.large.length) || (slides.small && slides.small.length)"
          id="sliders"
          class="grid-zone"
        >
          <!-- Slider large -->
          <Slider
            v-if="slides.large && slides.large.length"
            pagination-small
          >
            <template #slider-items>
              <SliderCard
                v-for="slide in slides.large"
                :key="slide.id"
                :title="{ text: slide.titre, color: slide.titre_couleur, }"
                :headline="{ text: slide.accroche, color: slide.accroche_couleur, }"
                :description="{ text: slide.description, color: slide.description_couleur, }"
                :background="{ color: slide.fond_couleur, image: slide.fond_image, }"
              >
                <template
                  v-if="slide.logo"
                  #header-image
                >
                  <img
                    :src="slide.logo"
                    :alt="$t('general.alt-logo-editeur')"
                  />
                </template>
                <template
                  v-if="slide.illustration"
                  #illustration
                >
                  <img
                    :src="slide.illustration"
                    :alt="$t('general.alt-image-illustration')"
                  />
                </template>
                <template #buttons>
                  <ButtonClassic
                    v-if="slide.btn_1_lien"
                    v-matomo-log-click="['slider-btn-1', slide.titre, slide.btn_1_label, ]"
                    balise-type="a"
                    :label="slide.btn_1_label"
                    variant="solid"
                    color="primary"
                    icon="left"
                    :ext-link="slide.btn_1_lien"
                  >
                    <template #left-icon>
                      <UilArrowRight />
                    </template>
                  </ButtonClassic>
                  <ButtonClassic
                    v-if="slide.btn_2_lien"
                    v-matomo-log-click="['slider-btn-2', slide.titre, slide.btn_2_label, ]"
                    balise-type="a"
                    :label="slide.btn_2_label"
                    variant="solid"
                    color="primary"
                    icon="left"
                    :ext-link="slide.btn_2_lien"
                  >
                    <template #left-icon>
                      <UilArrowRight />
                    </template>
                  </ButtonClassic>
                </template>
              </SliderCard>
            </template>
          </Slider>

          <!-- Slider small -->
          <Slider
            v-if="slides.small && slides.small.length"
            :interval="5"
            small
            pagination-small
          >
            <template #slider-items>
              <SliderCard
                v-for="slide in slides.small"
                :key="slide.id"
                :title="{ text: slide.titre, color: slide.titre_couleur, }"
                :headline="{ text: slide.accroche, color: slide.accroche_couleur, }"
                :description="{ text: slide.description, color: slide.description_couleur, }"
                :background="{ color: slide.fond_couleur, image: slide.fond_image, }"
              >
                <template
                  v-if="slide.logo"
                  #header-image
                >
                  <img
                    :src="slide.logo"
                    :alt="$t('general.alt-logo-editeur')"
                  />
                </template>
                <template
                  v-if="slide.illustration"
                  #illustration
                >
                  <img
                    :src="slide.illustration"
                    :alt="$t('general.alt-image-illustration')"
                  />
                </template>
                <template #buttons>
                  <ButtonClassic
                    v-if="slide.btn_1_lien"
                    v-matomo-log-click="['slider-btn-1', slide.titre, slide.btn_1_label, ]"
                    balise-type="a"
                    :label="slide.btn_1_label"
                    variant="solid"
                    color="primary"
                    size="small"
                    icon="left"
                    :ext-link="slide.btn_1_lien"
                  >
                    <template #left-icon>
                      <UilArrowRight />
                    </template>
                  </ButtonClassic>
                  <ButtonClassic
                    v-if="slide.btn_2_lien"
                    v-matomo-log-click="['slider-btn-1', slide.titre, slide.btn_2_label, ]"
                    balise-type="a"
                    :label="slide.btn_2_label"
                    variant="solid"
                    color="primary"
                    size="small"
                    icon="left"
                    :ext-link="slide.btn_2_lien"
                  >
                    <template #left-icon>
                      <UilArrowRight />
                    </template>
                  </ButtonClassic>
                </template>
              </SliderCard>
            </template>
          </Slider>
        </div>
      </section>

      <section
        v-if="currentPage === 1 && !newsAreLoading && news.length"
        id="nouveautes"
      >
        <h2 class="s5 title-separator">
          <span class="bold">{{ $t('filtre.nouveautes') }}</span>
          <!-- <router-link
            v-matomo-log-click="['toutes-nouveautés', ]"
            class="text-medium underline"
            :to="{ query:{ 'nouveaute': 1, }, }"
            >
            {{ $t('ressource-numerique.toutes-les-nouveautes') }}
            </router-link> -->
        </h2>
        <div
          v-if="!newsAreLoading"
          class="grid-zone"
        >
          <ProductItem
            v-for="(item, i) in news"
            :key="item.id"
            v-model="news[i]"
            :view="viewMode"
            small
          />
        </div>
        <InfiniteLoader v-else />
      </section>

      <section id="tout">
        {{ /* TODO: A remettre quand on aura des filtres propres */ }}
        <h2 class="s5 title-separator">
          <span class="bold">{{ $t('livres-manuels-papier.nos-produits-livres-et-manuels-papier') }}</span>
        </h2>
        <div v-if="!allProductsAreLoading && allProducts.length">
          <div
            id="all_products"
            class="grid-zone"
          >
            <template v-if="allProducts.length">
              <ProductItem
                v-for="(item, i) in allProducts"
                :key="item.id"
                v-model="allProducts[i]"
                :view="viewMode"
                small
              />
            </template>
          </div>
          <div class="pagination-container">
            <Pagination
              v-if="totalProducts > pageSize"
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="pageSize"
              @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
            />
          </div>
        </div>
        <ErrorPage
          v-else-if="!allProductsAreLoading"
          :title="$t('recherche.aucun-resultat')"
          :content="$t('recherche.aucun-produit-correspondant')"
        >
          <template #custom-icon>
            <img
              :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_zero_resultat.svg')"
              :alt="$t('recherche.illustration-zero-resultat')"
            />
          </template>
        </ErrorPage>

        <div v-else>
          <InfiniteLoader />
        </div>
      </section>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  InfiniteLoader,
  Pagination,
  Slider,
  SliderCard,
  PageContent,
} from "@lde/core_lde_vue";

import ProductItem from "@/components/products/ProductItem.vue";
import FilterSidebar from "@/components/search/filters/FilterSidebar.vue";
import HorizontalSearchPap from "@/components/search/HorizontalSearchPap.vue";
import ErrorPage from "@/components/layout/ErrorPage.vue";
import HomeMessages from "@/components/home/HomeMessages.vue";

import IconListView from "@/components/icons/IconListView.vue";
import {
  UilTable,
  UilFilter,
  UilTimes,
  UilArrowRight,
} from "@iconscout/vue-unicons";

import ListProducts from "@/mixins/mixinListProducts";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Vue de la liste des fournitures.
 */
export default {
  name: "LivresManuelsPapier",
  components: {
    PageContent,
    SliderCard,
    Slider,
    ButtonClassic,
    ButtonGroup,
    ProductItem,
    Pagination,
    HorizontalSearchPap,
    ErrorPage,
    HomeMessages,
    FilterSidebar,
    InfiniteLoader,
    IconListView,
    UilTable,
    UilFilter,
    UilTimes,
    UilArrowRight,
  },
  mixins: [ListProducts],
  data() {
    return {
      controller: new AbortController(),
      allFilters: [],
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "isHorsMarche", "sideCheckedFilters", "filters"]),
    navTitles() {
      const res = [{ label: this.$t("general.focus"), anchorId: "focus" }];

      if (!this.newsAreLoading && this.news.length) {
        res.push({ label: this.$t("filtre.nouveautes"), anchorId: "nouveautes" });
      }

      res.push({ label: this.$t("general.tout"), anchorId: "tout" });
      return res;
    },
  },
  watch: {
    "$route.query.page": {
      handler() {
        this.fetchAllArticles(false, false);
      },
    },
  },
  created() {
    this.fetchAllArticles(false, false);
    this.fetchSliders("papier");
  },
  methods: {
    /**
     * Récupère "tous les livres et manuels papier".
     * @param {Boolean} scroll Si true, scrolle jusqu'à la zone des produits.
     * @param {Boolean} resetPage Si true, affiche les résultats de la première page.
     * @return {Promise} Retourne toutes les fournitures.
     */
    fetchAllArticles(scroll = true, resetPage = true) {
      this.allProductsAreLoading = true;
      this.showFilterSidebar = false;
      if (scroll) {
        window.location.href = "#tout";
      }

      if (resetPage) {
        this.currentPage = 1;
        return this.$router.push({ query: { ...this.$route.query, page: 1 } });
      } if (this.$route.query?.page) {
        this.currentPage = parseInt(this.$route.query?.page, 10);
      }

      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
        home: true,
      };
      params.page = this.currentPage;

      const promise = Api().get("/papier/", { params })
        .then(({ data }) => {
          this.allProducts = data.results;
          this.totalProducts = data.count;
          this.allFilters = data.filtres;
          this.allProductsAreLoading = false;
        });

      return promise.catch((error) => {
        this.$toast.error({
          title: this.$t("toast.erreur"),
          content: error.response?.data?.detail,
        });
      });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_list_products.scss";
</style>
