<template>
  <div
    class="horizontal-search"
    :class="variant"
  >
    <h1
      v-if="variant === 'widget'"
      class="title s4 light flex-vcenter"
    >
      <IconPapierAlt />
      {{ $t("recherche.livres-et-manuels-papier") }}
    </h1>
    <form @submit.prevent="submitForm()">
      <p class="stop-word bold text-medium">
        {{ $t("recherche.rechercher-par") }}
      </p>
      <div
        class="flex-vcenter"
      >
        <InputClassic
          v-model="titre"
          class="input-titre"
          :label="$t('general.titre')"
          :placeholder="$t('recherche.renseigner')"
          :select-on-enter="true"
          inline
        />
      </div>
      <div class="flex-vcenter">
        <p class="stop-word inside-word bold text-medium">
          {{ $t("general.et") }}
        </p>
        <InputClassic
          v-model="auteur"
          class="input-titre"
          :label="$t('produit.auteur')"
          :placeholder="$t('recherche.renseigner')"
          :select-on-enter="true"
          inline
        />
      </div>
      <div class="flex-vcenter">
        <p class="stop-word inside-word bold text-medium">
          {{ $t("general.et") }}
        </p>
        <InputClassic
          v-model="editeur"
          class="input-titre"
          :label="$t('produit.editeur')"
          :placeholder="$t('recherche.renseigner')"
          :select-on-enter="true"
          inline
        />
      </div>
      <div
        v-if="variant === 'widget'"
        class="flex-vcenter form-submit-container"
      >
        <ButtonClassic
          class="form-submit"
          type="button"
          color="primary"
          variant="solid"
          icon="left"
          size="small"
          :disabled="buttonsDisabled"
          @click="resetFilters()"
        >
          <template #left-icon="{ iconSize, }">
            <IconTrashReset
              :height="iconSize"
              :width="iconSize"
            />
          </template>
        </ButtonClassic>
        <ButtonClassic
          :label="$t('recherche.lancer-la-recherche')"
          class="form-submit"
          type="submit"
          icon="left"
          variant="solid"
          color="secondary"
          :disabled="buttonsDisabled"
        >
          <template #left-icon="{ iconSize, }">
            <UilSearchAlt :size="iconSize" />
          </template>
        </ButtonClassic>
      </div>
      <template v-else>
        <ButtonClassic
          class="form-submit"
          type="submit"
          color="secondary"
          variant="special"
          icon="left"
          :title="$t('recherche.lancer-la-recherche')"
          :disabled="buttonsDisabled"
        >
          <template #left-icon="{ iconSize, }">
            <UilSearchAlt :size="iconSize" />
          </template>
        </ButtonClassic>
        <ButtonClassic
          class="form-submit"
          color="primary"
          variant="solid"
          icon="left"
          size="small"
          :disabled="buttonsDisabled"
          @click="resetFilters()"
        >
          <template #left-icon="{ iconSize, }">
            <IconTrashReset
              :height="iconSize"
              :width="iconSize"
            />
          </template>
        </ButtonClassic>
      </template>
    </form>
  </div>
</template>

<script>
import {
  InputClassic,
  ButtonClassic,
  IconTrashReset,
} from "@lde/core_lde_vue";
import { UilSearchAlt } from "@iconscout/vue-unicons";

import IconPapierAlt from "@/components/icons/IconPapierAlt.vue";

export default {
  name: "HorizontalSearchPap",
  components: {
    InputClassic,
    ButtonClassic,
    IconTrashReset,
    UilSearchAlt,
    IconPapierAlt,
  },
  props: {
    variant: {
      type: String,
      validator: (value) => ["default", "widget"].includes(value),
      default: "default",
    },
  },
  data() {
    return {
      titre: "",
      auteur: "",
      editeur: "",
    };
  },
  computed: {
    buttonsDisabled() {
      return !this.titre && !this.auteur && !this.editeur;
    },
  },
  methods: {
    /**
     * Remet à zéro les filtres des dropdowns.
     */
    resetFilters() {
      this.titre = "";
      this.auteur = "";
      this.editeur = "";
    },
    /**
     * Déclenche la recherche.
     */
    submitForm() {
      const query = {
        only: "papiers",
        quicksearch: true,
      };
      if (this.titre) {
        query.papiers_adv_libelle = `${this.titre}`;
      }
      if (this.auteur) {
        query.papiers_adv_auteurs = `${this.auteur}`;
      }
      if (this.editeur) {
        query.papiers_adv_editeur = `${this.editeur}`;
      }
      this.$router.push({
        name: "search",
        query,
      });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/search/horizontal_search.scss";
</style>
